
  import { computed, defineComponent, ref, getCurrentInstance } from "vue"
  import { CollectionResourceDoc } from '@/models/jsonapi'
  import { Ticket } from "@/models/tickets";
  import { BvTableCtxObject } from "bootstrap-vue"
  import { apiDataMorpher, extractRelationshipIds } from "@/utils/apiDataHelper"

  export default defineComponent({
    components: {},
    setup(_) {
      const root = getCurrentInstance().proxy
      const totalItems = computed(() => root.$store.state.tickets.pagination.totalItems)

      const getItems = async(ctx: BvTableCtxObject) => {
        const queryParams = {
          'filter[archived]': false,
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
          'include': 'tasks',
          'sort': '-createdAt',
        }
        await root.$store.dispatch('tickets/load', queryParams)
        return root.$store.state.tickets.data
      }

      const columns = ref([
        {
          component: () => import("@/components/shared/table/cells/LinkCell.vue"),
          key: "ticketableType",
          title: "Typ",
          sortable: true,
          options: {
            contentFunction: (data:any) => {
              return data.item.attributes.ticketableType
            },
            hrefFunction: (data: any) => {
              const poly = data.item.relationships.ticketable.data
              return `/${poly.type}/${poly.id}`
            },
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.category",
          title: "Kategorie",
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/LinkCell.vue"),
          key: "attributes.subject",
          title: "Betreff",
          sortable: true,
          options: {
            hrefFunction: (data: any) => `/${data.item.type}/${data.item.id}`,
            contentFunction: (data: any) => {
              const item = data.item.attributes
              return item.subject || 'Kein Betreff'
            }
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.tasks.first.attributes.note",
          title: "Termin Notiz",
          options: {
            contentFunction: (data: any) => {
              // const tasks = data.item.attributes?.tasks
              const tasks = data.item.attributes?.tasks?.filter((task: any) => {
                return task?.attributes?.actionTypeId === 'p_t_notified'
              })
              if (tasks && tasks.length > 0) {
                return tasks[0].attributes?.note || 'keine'
              } else {
                return 'keine'
              }
            }
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.tasks.count",
          title: "Benachrichtigungen",
          options: {
            contentFunction: (data: any) => {
              const tasks = data.item.attributes?.tasks?.filter((task: any) => {
                return task?.attributes?.actionTypeId === 'p_t_notified'
              })
              return tasks?.length || 0
            }
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.archived",
          title: "Archiviert",
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "messageCount",
          title: "Nachrichten",
          sortable: true,
          options: {
            contentFunction: (data: any) => data.item.attributes.messages.length
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "lastMessage",
          title: "Letzte Nachricht",
          sortable: true,
          options: {
            contentFunction: (data: any) => {
              return data.item.attributes.messages.map((m: any) => m.updatedAt).sort()[0]
            },
            type: 'date'
          }
        },
      ])

      return {
        getItems,
        totalItems,
        columns,
      }
    }
  })
